

























































































































































































































































































































































































































































































































































































































































































































































































import InputNumber from '@/components/InputNumber.vue';
import OnceButton from '@/components/OnceButton.vue';
import cls from '@/common/classification';
import { Component, Vue } from 'vue-property-decorator';
import {
  MOBILE_PASMO_MAINTENANCE_START,
  MOBILE_PASMO_MAINTENANCE_STOP,
  MOBILE_PASMO_BALANCE_LIMIT
} from '@/common/constants';
import { OpCardList } from '@/models/opcards/OpCard';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { PasmoChargeLimit } from '@/models/PasmoCharge';
import { PasmoChargeRepository } from '@/repositories/PasmoChargeRepository';

const MAX_LENGTH_ACCESS_TOKEN = 512;
const MAX_LENGTH_BALANCE = 5;
const LENGTH_IDI = 17;

export type Query = {
  accessToken: string;
  idi: string;
  balance: number;
  isAnonymous: boolean;
};

declare var PASMOPoint: {
  success: () => {};
  cancel: () => {};
  error: (message: string) => {};
  open_browser: (url: string) => {};
};

@Component({ components: { InputNumber, OnceButton } })
export default class P0215 extends Vue {
  readonly pasmoChargeRepo = new PasmoChargeRepository();
  readonly opCardRepo = new OpCardRepository();

  // メンテナンス関連のパラメータ
  readonly now = new Date();
  readonly maintenanceStart = MOBILE_PASMO_MAINTENANCE_START;
  readonly maintenanceStop = MOBILE_PASMO_MAINTENANCE_STOP;

  // チャージ制限にまつわる情報 ページ初回描画時に初期化される
  chargeLimit: PasmoChargeLimit | null = null;
  // ページ全体のエラーメッセージ
  // OP 残高、今月チャージ可能額の取得に失敗した場合などに値がセットされる
  pageErrMsg = '';
  // フォームのエラーメッセージ
  formErrMsg = '';
  formErrMsgModal = '';
  // ユーザが入力するチャージ申請額 (手数料を含まない)
  chargeAmount = 0;
  // 入力欄に表示するためのコンマ付きチャージ申請額の数値文字列
  chargeAmountInput = '';
  // 利用規約 (Terms os Service; ToS) がチェックされているか
  isTosChecked = false;
  // 確認モーダルを表示するか
  isConfirmationModalOpened = false;
  // サービス規約のモーダルを表示するか
  isTosModalOpened = false;
  // ローディングアイコンを表示するか
  btnLoading = false;
  // 入力値がチャージ申請ポイントとして正当であるか
  isValidChargeAmount = false;

  // クエリパラメータから取得される情報
  pasmoToken = '';
  pasmoBalance = 0;
  idi = '';
  isAnonymous = false;
  expirationDt = new Date(0);

  // モーダル最大高さ
  // Android WebView にて vh の取得が不可のため、動的に取得した値を設定する
  scroll_max_height_style = '';

  // リサイズイベントのフラグ
  isFlagResize = false;

  // テキストの中身（高さ）によって「もっと見る」矢印を表示するか（3行以上で非表示にするか）どうかを判定
  calc_modal_height() {
    if (this.isFlagResize) {
      return;
    }

    this.isFlagResize = true;

    // リサイズイベントの発生回数を減らす
    window.requestAnimationFrame(() => {
      this.scroll_max_height_style =
        'max-height:' + (window.innerHeight - 140) + 'px';
      this.isFlagResize = false;
    });
  }

  mounted() {
    // リサイズイベント追加
    window.addEventListener('resize', this.calc_modal_height);
    this.calc_modal_height();
  }

  async created(): Promise<void> {
    // クエリパラメータから PASMO 残高を取得し、PASMO 残高を `this.pasmoBalance` にセットする
    // パース・バリデーションにて例外が発生したとき、/error にリダイレクトする
    try {
      const query = this.parseAndValidateQuery();
      this.pasmoToken = query.accessToken;
      this.pasmoBalance = query.balance;
      this.idi = query.idi;
      this.isAnonymous = query.isAnonymous;
    } catch {
      this.$router.push({
        name: 'error',
        query: { error_description: '不正なリクエストです' }
      });
      return;
    }

    // SF残高が20,000円を超えている場合エラー画面へ遷移
    if (this.pasmoBalance > MOBILE_PASMO_BALANCE_LIMIT) {
      this.$router.push({
        name: 'error',
        query: { error_description: '不正なリクエストです' }
      });
      return;
    }

    // クエリパラメータ `expiration_dt` を処理
    let { expiration_dt } = this.$route.query;
    if (Array.isArray(expiration_dt)) {
      expiration_dt = expiration_dt.shift() || '';
    }
    this.expirationDt = new Date(expiration_dt);

    // 今月のチャージ可能ポイントを Titan から取得
    try {
      this.chargeLimit = await this.fetchPasmoChargeLimit();
      // 当月チャージ済金額が5,000円以上
      if (this.availableChargeAmount <= 0) {
        this.pageErrMsg = this.$msg.get('2000406');
      }
      // OP残高が `this.chargeLimit.MobilePasmoMinimumAmount` ポイント未満
      await this.fetchOpBalance();
      if (this.chargeLimit.MobilePasmoMinimumAmount > this.opBalance) {
        this.pageErrMsg = this.$msg.get('2000408', {
          num: this.chargeLimit.MobilePasmoMinimumAmount
        });
      }
    } catch {
      this.pageErrMsg = this.$msg.get('2000078');
    }
  }

  // parseAndValidateQuery は、URL のクエリパラメータ `query` をパースし、accessToken, idi, balance, isAnonymous を返します。
  // eslint-disable-next-line complexity
  parseAndValidateQuery(): Query {
    // `query` という名前のクエリパラメータが URL に含まれていることが期待される。
    // e.g. https://one-odakyu.com/mobile-pasmo-charge?query=xxx
    //                                                 ^^^^^^^^^
    let { query } = this.$route.query;

    // 複数のクエリパラメータが含まれるとき、最初のものを採用する。
    // e.g. https://one-odakyu.com/mobile-pasmo-charge?query=xxx&query=yyy
    //                                                 ^^^^^^^^^
    if (Array.isArray(query)) {
      query = query.shift() || '';
    }

    // `query` をパース
    const params = new URLSearchParams('?' + atob(decodeURI(query)));
    const accessToken = params.get('accessToken') || '';
    const idi = params.get('idi') || '';
    const balance = params.get('balance') || '';
    const isAnonymous = params.get('isAnonymous') || '';

    // バリデーション
    if (
      !(accessToken.length > 0 && accessToken.length <= MAX_LENGTH_ACCESS_TOKEN)
    ) {
      throw new Error(
        `access_token has invalid length of ${accessToken.length}`
      );
    }
    if (idi.length !== LENGTH_IDI) {
      throw new Error(`idi has invalid length of ${idi.length}`);
    }
    if (!(balance.length > 0 && balance.length <= MAX_LENGTH_BALANCE)) {
      throw new Error(`balance has invalid length of ${balance.length}`);
    }
    if (Number.isNaN(Number(balance))) {
      throw new Error(`balance is not a number: ${balance}`);
    }
    if (isAnonymous !== '0' && isAnonymous !== '1') {
      throw new Error(`is_anonymous is invalid: ${isAnonymous}`);
    }

    return {
      accessToken,
      idi,
      balance: Number(balance),
      isAnonymous: isAnonymous === '1'
    };
  }

  validateInput(): boolean {
    const res = this.inputValidator();
    this.isValidChargeAmount = res;
    return res;
  }

  // inputValidator は this.chargeLimit, this.chargeAmount, this.chargeAmountInput を評価し、
  // 異常がないとき、true を返します。
  // 異常がある場合、this.formErrMsg に値を設定し、false を返します。
  inputValidator(): boolean {
    this.formErrMsg = '';

    // chargeLimit の null チェック
    // ページの初回描画時に chargeLimit は初期化されるため、通常 chargeLimit が null であることはない。
    if (!this.chargeLimit) {
      this.formErrMsg = this.$msg.get('2000011');
      return false;
    }

    // チャージ金額が入力されていない
    if (!this.chargeAmountInput) {
      this.formErrMsg = this.$msg.get('2000405');
      return false;
    }

    // チャージ金額が半角数字でない
    if (!/^[\d,]*$/.test(this.chargeAmountInput)) {
      this.formErrMsg = this.$msg.get('2000063');
      return false;
    }

    // 入力金額が `this.chargeLimit.MobilePasmoMinimumAmount` 円未満
    if (this.chargeAmount < this.chargeLimit.MobilePasmoMinimumAmount) {
      this.formErrMsg = this.$msg.get('2000402', {
        num: this.chargeLimit.MobilePasmoMinimumAmount
      });
      return false;
    }

    // 入力金額が5,000円より大きい
    if (this.chargeAmount > 5000) {
      this.formErrMsg = this.$msg.get('2000403');
      return false;
    }

    // 入力金額が `this.chargeLimit.MobilePasmoUnitAmount` 円単位でない
    if (this.chargeAmount % this.chargeLimit.MobilePasmoUnitAmount !== 0) {
      this.formErrMsg = this.$msg.get('2000401', {
        num: this.chargeLimit.MobilePasmoUnitAmount
      });
      return false;
    }

    // 入力金額が OP 残高より大きい
    if (this.chargeAmount > this.opBalance) {
      this.formErrMsg = this.$msg.get('2000404');
      return false;
    }

    // 入力金額とSF残高の和が20,000円より大きい
    if (this.chargeAmount + this.pasmoBalance > MOBILE_PASMO_BALANCE_LIMIT) {
      this.formErrMsg = this.$msg.get('2000407', {
        num: MOBILE_PASMO_BALANCE_LIMIT.toLocaleString()
      });
      return false;
    }

    // 入力金額と当月チャージ済金額の和が5,000円より大きい
    if (this.chargeAmount + (5000 - this.availableChargeAmount) > 5000) {
      this.formErrMsg = this.$msg.get('2000403');
      return false;
    }

    return true;
  }

  // fetchPasmoChargeLimit は Titan からチャージ上限額を取得し、Promise<PasmoChargeLimit> を返します。
  async fetchPasmoChargeLimit(): Promise<PasmoChargeLimit> {
    return this.pasmoChargeRepo.getPasmoChargeLimit();
  }

  // isUnderMaintenance は、ページ表示時刻とメンテナンスか開始時刻・終了時刻を評価し、
  // メンテナンス中であれば true を返します。
  get isUnderMaintenance(): boolean {
    return this.now >= this.maintenanceStart && this.now < this.maintenanceStop;
  }

  // opBalance はユーザの OP 残高を返します。
  get opBalance(): number {
    return Number(this.$store.state.op.balance.previousBalance);
  }

  // opCards はユーザの OpCardList を返します。
  get opCards(): OpCardList {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  // availableChargeAmount はチャージ上限額を返します。
  get availableChargeAmount(): number {
    return this.chargeLimit?.availableChargeAmount || 0;
  }

  // isConfirmationButtonActive は「確認する」ボタンを押下することができるとき、true を返します。
  get isConfirmationButtonActive(): boolean {
    return (
      this.chargeAmount !== 0 &&
      this.pageErrMsg === '' &&
      this.formErrMsg === '' &&
      this.isTosChecked &&
      this.isValidChargeAmount
    );
  }

  // openTosModal は利用規約モーダルを表示します。
  openTosModal() {
    this.isTosModalOpened = true;
  }

  // closeTosModal は利用規約モーダルを閉じます。
  closeTosModal() {
    this.isTosModalOpened = false;
  }

  // openConfirmationModal は確認モーダルを表示します。
  openConfirmationModal() {
    this.isConfirmationModalOpened = this.validateInput();
  }

  // closeConfirmationModal は確認モーダルを閉じます。
  closeConfirmationModal() {
    this.isConfirmationModalOpened = false;
  }

  async fetchOpBalance() {
    // すでにOP残高を取得している場合、何もしない
    if (
      this.$store.state.op.balance.returnStatus ===
      cls.POINT_DEAL_STATUS_FROM_ECOP.SUCCESS.CD
    ) {
      return;
    }

    const balance = await this.opCardRepo.getOpBalance();
    this.$store.commit('setOpBalance', balance);
  }

  // register はモバイル PASMO チャージ API を呼び出します。
  async register() {
    // 現在時刻が有効期限よりも過去であるときのみ、API を呼び出す
    if (new Date().getTime() < this.expirationDt.getTime()) {
      // チャージボタン押下後、一定時間（16秒）経過したタイミングでエラーメッセージを表示の上、Webviewを閉じる
      const timeoutTimer = setTimeout(() => {
        PASMOPoint.error(this.$msg.get('2000426'));
      }, 16000);
      try {
        this.btnLoading = true;
        await this.pasmoChargeRepo.postMobilePasmoCharge(
          this.opCards.selectMainCard.odakyuCustomerNo,
          this.opBalance,
          this.chargeAmount,
          this.pasmoToken,
          this.pasmoBalance,
          this.idi,
          this.isAnonymous
        );
        PASMOPoint.success();
      } catch (errCode) {
        this.handlePostMobilePasmoChargeErr(errCode);
      } finally {
        clearTimeout(timeoutTimer);
      }
      return;
    }
    this.formErrMsgModal = this.$msg.get('2000400');
    PASMOPoint.error(this.$msg.get('2000400'));
  }

  // モバイルPASMOアプリWebViewにてリンクを選択した際、WebView内遷移を防止し標準ブラウザに遷移させるため、PASMO.open_browserを利用する
  // それ以外のブラウザにてリンクを選択した場合は、aタグを選択した場合の挙動を継承する
  openExternalDomain(event: Event) {
    // Event.targetの型を判定しないとcompileErrorが出る
    if (!(event.target instanceof HTMLAnchorElement)) {
      return;
    }

    let url = event.target.href;
    if (url == '' || url == null) {
      return;
    }

    // 使用ブラウザがPASMOのWebViewであればPASMO.open_browserを使用して遷移を行うことができる
    // PASMOアプリのWebViewで無い場合、PASMOPoint.open_browserはエラーを出力する
    try {
      PASMOPoint.open_browser(url);
    } catch {
      // aタグにblankの設定があるかを判定
      let isBlank = event.target.target != null && event.target.target != '';
      if (isBlank) {
        window.open(url, '_blank');
        return;
      }
      location.href = url;
    }
  }

  // eslint-disable-next-line complexity
  handlePostMobilePasmoChargeErr(errCode: number) {
    switch (errCode) {
      case 40032:
        PASMOPoint.error(this.$msg.get('2000412'));
        break;
      case 40033:
        PASMOPoint.error(this.$msg.get('2000413'));
        break;
      case 40034:
      case 50022:
        PASMOPoint.error(this.$msg.get('2000414'));
        break;
      case 40035:
        PASMOPoint.error(this.$msg.get('2000415'));
        break;
      case 40036:
        PASMOPoint.error(this.$msg.get('2000416'));
        break;
      case 40037:
        PASMOPoint.error(this.$msg.get('2000417'));
        break;
      case 40038:
        PASMOPoint.error(this.$msg.get('2000418'));
        break;
      case 40039:
        PASMOPoint.error(this.$msg.get('2000419'));
        break;
      case 40040:
      case 40043:
        PASMOPoint.error(this.$msg.get('2000420'));
        break;
      case 40041:
        PASMOPoint.error(this.$msg.get('2000421'));
        break;
      case 40042:
        PASMOPoint.error(this.$msg.get('2000422'));
        break;
      case 40044:
      case 40045:
      case 50020:
      case 50021:
      case 50025:
        PASMOPoint.error(this.$msg.get('2000411'));
        break;
      case 40046:
      case 40048:
        PASMOPoint.error(this.$msg.get('2000410'));
        break;
      case 40047:
        PASMOPoint.error(this.$msg.get('2000423'));
        break;
      case 50019:
        PASMOPoint.error(this.$msg.get('2000409'));
        break;
      case 50023:
        PASMOPoint.error(this.$msg.get('2000424'));
        break;
      case 50024:
        PASMOPoint.error(this.$msg.get('2000425'));
        break;
      case 50027: // DBへの結果反映にてエラーが生じたパタンであり、チャージ処理自体は成功しているため、successを返却する
        PASMOPoint.success();
        break;
      default:
        PASMOPoint.error(this.$msg.get('2000011'));
        break;
    }
  }
}
